<header class="disclaimer__header sm:block hidden w-full bg-secondary 2xl:p-0 py-0 px-5 relative z-[2]">
	<nav class="flex items-center justify-between w-[1440px] max-w-full mx-auto">
		<div class="md:p-0 py-3 px-4 flex flex-wrap gap-8 items-center">
			<ul class="content__link-sites flex flex-wrap items-center gap-4">
				@for (item of sites; track $index) {
					@if (item.separator) {
						<li class="cls-separator h-[20px] w-[1px] bg-white"></li>
					} @else {
						<li class="cls-item">
							@if (item.url) {
								@if (item.external) {
									<a [href]="item.url" [target]="item.target" class="clsi-link font__title uppercase {{ item.active ? 'active_link text-primary': 'text-white' }}">{{ item.name }}</a>
								} @else {
									<a [routerLink]="[item.url]" [target]="item.target" class="clsi-link font__title uppercase {{ item.active ? 'active_link text-primary': 'text-white' }}">{{ item.name }}</a>
								}
							} @else {
								<span class="clsi-link font__title uppercase {{ item.active ? 'active_link text-primary': 'text-white' }}">{{ item.name }}</span>
							}
						</li>
					}
				}
			</ul>
		</div>

		@if (env.type === 'club-bolivar') {
			<div class="md:flex hidden flex-wrap gap-8 items-center">
				<div *ngIf="dataGoal" class="w-auto text-white text-center">
					<h5 class="font__title uppercase text-lg font-medium"><span>{{ dataGoal.title }}</span></h5>
					<h5 class="font__title uppercase text-lg font-medium"><span>{{ dataGoal.subtitle }}</span></h5>
				</div>
				<a routerLink="/" class="w-[140px] h-[60px] relative overflow-hidden text-primary hover:text-white">
					<img src="/assets/img/general/become-a-member.gif" alt="Hazte Socio" class="absolute left-0 right-0 top-0 bottom-0 scale-[2] opacity-20">
					<span class="w-full text-center absolute left-1/2 top-[150%] -translate-x-1/2 -translate-y-1/2 animate-[textUp_1s_3s_forwards] transition-transform font-medium font__title uppercase">¡Hazte Socio!</span>
				</a>
			</div>
		}

	</nav>
</header>