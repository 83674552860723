import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { XtrasService } from '../../../../../../services/xtras.service';
import { AuthService } from '../../../../../../services/auth.service';
import { UserModel } from '../../../../../../services/user-model';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonCapYeiComponent } from '../../../../../../components/button/button.component';
import { DropdownMenuCapYeiComponent } from '../../../../../../components/dropdown-menu/drowdown-menu.component';
import { HttpClientProvider } from '../../../../../../services/http-client';
import { LoadingService } from '../../../../../../services/loading.service';
import { SettingsProvider } from '../../../../../../services/settings';
import { ENV } from '../../../../../../interfaces/interfaces';
import { getEnvironment } from '../../../../../../environment/environment';

@Component({
  selector: 'app-disclaimer-1',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  standalone: true,
	imports: [
    CommonModule, 
    RouterModule, 
    MatMenuModule,
    ButtonCapYeiComponent,
    DropdownMenuCapYeiComponent,
  ]
})
export class Disclaimer1Component implements OnInit {

  env:ENV = getEnvironment();

  private unsubscribe: Subscription[] = [];

  dataGoal: any = null;

  sites: {
    url?: string,
    external?: boolean,
    target?: '_self'|'_blank',
    active?: boolean,
    name?: string,
    separator: boolean,
  }[] = [];

  constructor(
    public xServices: XtrasService,
    public authService: AuthService,
    public userModel: UserModel,
    private http: HttpClientProvider,
  ) {
    if(this.env.type === 'club-bolivar') {
      this.sites = [
        { name: 'Club Bolívar', url: 'https://clubbolivar.com/', external: true, target: '_blank', separator: false },
        { separator: true },
        { name: 'Socio Centenario', active: true, separator: false },
        { separator: true },
        { name: 'Bolívar Store', url: '/my-session', external: false, separator: false },
      ];
      this.dataGoal = null;
      const subscr = this.http.getRequest(SettingsProvider.getUrl(`goal`)).subscribe((result: any) => {
        if(result['status']&&result['data']) {
          this.dataGoal = result['data']
        }
      }, () => {
      });
      this.unsubscribe.push(subscr);
    } else if(this.env.type === 'the-strongest') {
      this.sites = [
        { name: 'Sitio Web', url: 'https://clubthestrongest.bo/', external: true, target: '_blank', separator: false },
        { separator: true },
        { name: 'Abonos', active: true, separator: false },
        { separator: true },
        { name: 'Tienda', url: 'https://tienda.clubthestrongest.bo/', external: true, separator: false },
      ];
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
